import React from "react"
import "../styles.css"
import Navbar from "../components/Navbar"
import videos from "../content/videos.json"

export default function Home() {
  return (
  <div>
    <Navbar></Navbar>
        <div class="content">
        {videos.items.map(video => (      
          <div class="video">
              <div class="video-title peter">{video.text}</div>
              <div class="video-wrapper">
                <iframe
                src={`${video.src}?title=0&byline=0&portrait=0`}
                class="video-iframe"
                frameborder="0"
                allow="autoplay; fullscreen"
                width="100%"
                controls="0"
                allowfullscreen
              ></iframe>
          </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
        <div class="description"> 
                {video.description.map(description => (
                  <span class="descitem">{description.descriptionitem}&nbsp;</span>
                ))}
              </div>
      </div>)
      )}
</div>
</div>
)}